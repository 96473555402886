import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import { ShowAlert } from "../../../../../../GlobalComponents/ShowAlert/ShowAlert";
import { b2cUserSendOtpAction } from "../../../Actions/B2CSubscription.action";
import { useDispatch } from "react-redux";
import { registerB2CUser } from "../../../../../../Redux/B2CSubscription-Slice/Subscription-slice";
import { validateEmail, validateMobileNumber } from "../../../Common/Regx.check.fn";

const Registration = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (
      !validateEmail(userData.email) ||
      !validateMobileNumber(userData.mobile)
    )
      return ShowAlert("warning", "Please enter valid email and mobile.");
    try {
      setLoading(true);

      const payload = {
        countryCode: "91",
        email: userData.email,
        number: userData.mobile,
      };
      dispatch(registerB2CUser(userData));

      const res = await b2cUserSendOtpAction(payload);
      setLoading(false);

      // Soo change the state..........
      if (res.data.success) setStep("VERIFY_OTP");
    } catch (error: any) {
      setLoading(false);
      return ShowAlert(
        "warning",
        error?.response?.data?.message || "Please try again later.."
      );
    }
  };
  return (
    <Flex
      // border={"2px solid red"}
      marginTop={isMobile ? "35px" : "0px"}
      width={isMobile ? "500px" : "584px"}
      height={isMobile ? "277.983px" : "522px"}
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
    >
      <Flex
        width="auto"
        bg="white"
        height={isMobile ? "277.983px" : "450px"}
        boxShadow="xl"
        borderRadius="md"
        overflow="hidden"
      >
        {/* Right side with the form */}
        <Box flex="1.5" p="8" bg="white">
          <Heading
            mt={0}
            color="#333"
            fontSize={isMobile ? "9.586px" : "18px"}
            fontFamily="Poppins"
            fontWeight={isMobile ? "500" : "500"}
            lineHeight={isMobile ? "19.171px" : "36px"}
            letterSpacing="1.08px"
            fontStyle="normal"
            textAlign="center"
            as="h2"
            size="lg"
            mb={isMobile ? "2" : "2"}
          >
            Welcome
          </Heading>
          <Text
            mb={isMobile ? "3" : "4"}
            color="#333"
            fontSize={isMobile ? "6.39px" : "12px"}
            fontFamily="Poppins"
            fontWeight="400"
            lineHeight={isMobile ? "14.996px" : "36px"}
            letterSpacing="1.08px"
            fontStyle="normal"
            textAlign="center"
          >
            REGISTER AS A SUBSCRIBER
          </Text>
          <form>
            <Flex mb="4">
              <Box position={"relative"}>
                <Text
                  position={"absolute"}
                  bg="white"
                  top={isMobile ? "-2" : "-3"}
                  zIndex={10}
                  left={"15px"}
                  color="#424242"
                  fontSize="10px"
                  fontFamily="Poppins"
                  fontWeight="300"
                  lineHeight={isMobile ? "15.53px":"22.53px"} /* 225.3% of 10px */
                  fontStyle="normal"
                >
                  First Name
                </Text>
                <Input
                  name="firstName"
                  maxH={isMobile ? "25.562" : "48px"}
                  value={userData.firstName}
                  onChange={handleChange}
                  width={isMobile ? "127.808px" : "240px"}
                  // placeholder="First Name"
                  mr="2"
                  bg="white"
                  borderColor="gray.300"
                />
              </Box>
              <Box mb={isMobile ? "1" : "4"} position={"relative"}>
                <Text
                  position={"absolute"}
                  bg="white"
                  top={isMobile ? "-2" : "-3"}
                  zIndex={10}
                  left={"15px"}
                  color="#424242"
                  fontSize="10px"
                  fontFamily="Poppins"
                  fontWeight="300"
                  lineHeight={isMobile ? "15.53px":"22.53px"} /* 225.3% of 10px */
                  fontStyle="normal"
                >
                  Last Name
                </Text>
                <Input
                  maxH={isMobile ? "25.562" : "48px"}
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleChange}
                  width={isMobile ? "127.808px" : "240px"}
                  // placeholder="Last Name"
                  bg="white"
                  borderColor="gray.300"
                />
              </Box>
            </Flex>
            <Box mb={isMobile ? "1" : "4"} position={"relative"}>
              <Text
                position={"absolute"}
                bg="white"
                top={isMobile ? "-2" : "-3"}
                zIndex={10}
                left={"15px"}
                color="#424242"
                fontSize="10px"
                fontFamily="Poppins"
                fontWeight="300"
                lineHeight={isMobile ? "15.53px":"22.53px"} /* 225.3% of 10px */
                fontStyle="normal"
              >
                Email
              </Text>
              <Input
                maxH={isMobile ? "25.562" : "48px"}
                name="email"
                value={userData.email}
                onChange={handleChange}
                // placeholder="Email"
                type="email"
                mb="4"
                bg="white"
                borderColor="gray.300"
              />
            </Box>
            <Box mb="1" position={"relative"}>
              <Text
                position={"absolute"}
                bg="white"
                top={isMobile ? "-2" : "-3"}
                zIndex={10}
                left={"15px"}
                color="#424242"
                fontSize="10px"
                fontFamily="Poppins"
                fontWeight="300"
                lineHeight={isMobile ? "15.53px":"22.53px"} /* 225.3% of 10px */
                fontStyle="normal"
              >
                Contact Number
              </Text>
              <Input
                maxH={isMobile ? "25.562" : "48px"}
                name="mobile"
                value={userData.mobile}
                onChange={handleChange}
                // placeholder="Contact Number"
                type="tel"
                bg="white"
                borderColor="gray.300"
              />
            </Box>
            <Text
              mb="5"
              color="#919598"
              fontSize={isMobile ? "5px":"10px"}
              fontFamily="Poppins"
              fontWeight="400"
              lineHeight={isMobile ? "8px":"16px"} /* 160% of 10px */
              fontStyle="normal"
            >
              One time password (OTP) will be sent.
            </Text>
            <Button
              maxH={isMobile ? "25.562" : "48px"}
              fontSize={isMobile ? "8.521px": "16px"}
              fontWeight={"500px"}
              isLoading={loading}
              loadingText={"Loading.."}
              onClick={handleSubmit}
              width="full"
              bg="#01A7B5"
              color="white"
              _hover={{ bg: "teal.600" }}
            >
              Send OTP
            </Button>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Registration;
