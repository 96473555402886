import {
  Box,
  Button,
  Heading,
  Input,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  b2cVerifyOtpAction,
  createB2CUserAction,
} from "../../../Actions/B2CSubscription.action";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../../GlobalComponents/ShowAlert/ShowAlert";
import {
  createBen,
  userRegOtpPayload,
  userRegPayload,
} from "../../../Common/Payloads";
import { createBeneficiariesForB2c } from "../../../../../AddBeneficiaryMinDetails/Action/createBenficiaries";
import { saveSubscriber } from "../../../../../../Redux/Subscriber-Slice/Subscriber-slice";
import Confetti from "react-confetti";

const VerifyOtp = ({ setStep }: any) => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [showConfetti, setShowConfetti] = useState(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const userData = useSelector((state: any) => state.subscription);
  const handleSubmitOtp = async () => {
    try {
      setLoading(true);

      const verifyOtp = userRegOtpPayload(userData, otp);
      const verify = await b2cVerifyOtpAction(verifyOtp);

      // After successfully verifying the otp create the USER;
      if (verify.success) {
        // create the users hitting the user create api.....
        const userRgPayload = userRegPayload(userData);

        // After that it will create user + subscriber.. then we have to work on create beneficiary;
        let res = await createB2CUserAction(userRgPayload);
        const benData = createBen(res.data);

        const benRes = await createBeneficiariesForB2c(benData, dispatch);
        dispatch(saveSubscriber(benRes.data[0].response.data));

        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000); // Stop confetti after 3 seconds

        setLoading(false);
        setStep("SUBSCRIBE_PLAN");
      }
      return ShowAlert("success", "Successfully verified otp..");
    } catch (error: any) {
      setLoading(false);
      console.log(
        error?.response?.data,
        "###########handleSubmitOtp###########"
      );
      return ShowAlert(
        "warning",
        error?.response?.data?.message || "Error while verifying the OTP..."
      );
    }
  };
  return (
    <Box>
      {showConfetti && <Confetti />}
      <Box
        // border={"2px solid red"}
        marginTop={"50px"}
        margin={"auto"}
        maxW={isMobile ? "370px" : "502px"}
        height={isMobile ? "260.819px" : "400px"}
        bg={"white"}
      >
        <Box
          mt={8}
          // border={"2px solid red"}
          width={isMobile ? "320px" : "500px"}
          justifyContent={"center"}
        >
          <Box
            mt={"30px"}
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            textAlign={"center"}
            alignContent={"center"}
          >
            <Box marginTop={isMobile ? "8" : "14"}>
              <Heading size="xs" fontSize={isMobile ? "12.39px" : "20px"}>
                Verify OTP
              </Heading>
            </Box>
            <Box
              // border={"2px solid red"}
              margin={"auto"}
              marginTop={"14px"}
              maxW={isMobile ? "172.493px" : "372.493px"}
            >
              {" "}
              <Text
                sx={{
                  color: "#919598",
                  textAlign: "center",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: isMobile ? "7.434px" : "14px",
                  fontStyle: "normal",
                  fontWeight: isMobile ? "400" : "500",
                  lineHeight: isMobile ? "12px" : "24px", // 171.429%
                  textTransform: "camelCase",
                }}
              >
                Please enter One time password( OTP) sent to your mobile number
                (+91 0000000000)
              </Text>
            </Box>
          </Box>
          <Box
            margin={"auto"}
            mt="26px"
            textAlign={"center"}
            borderRadius={"8px"}
          >
            <Box position={"relative"}>
              <Text
                position={"absolute"}
                fontSize={isMobile ? "6.195px" : "10px"}
                bg="white"
                top={isMobile ? "-1.5" : "-3"}
                zIndex={10}
                left={"65px"}
                width={"40px"}
              >
                OTP
              </Text>
              <Input
                onChange={(e) => setOtp(e.target.value)}
                width={isMobile ? "246.744px" : "398.281px"}
                height={isMobile ? "32px" : "51.213px"}
                variant="outline"
                placeholder="* * * *"
              />
            </Box>
          </Box>
          <Box
            margin={"auto"}
            mt={isMobile ? "25px" : "35px"}
            textAlign={"center"}
          >
            <Button
              onClick={handleSubmitOtp}
              isLoading={loading}
              loadingText={"Loading.."}
              height={isMobile ? "32px" : "51.213px"}
              width={isMobile ? "246.744px" : "398.281px"}
              color={"white"}
              fontSize={isMobile ? "9.912px" : "16px"}
              borderRadius="8px"
              background="#00A7B5"
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyOtp;
