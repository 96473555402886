export const PlanStyle = {
  chooseNobenStyle: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.64px",
    color: "#000",
    fontFamily: "Poppins, sans-serif",
    fontVariantNumeric: "lining-nums tabular-nums",
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
  noOfBenInnerStyle: {
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  headingStyle: {
    color: "#000",
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.64px",
  },
};
