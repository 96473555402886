import {
    Box,
    Button,
    Flex,
    FormControl,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import OTPInput from "react-otp-input";
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as Yup from "yup";
import "./root.css";
import loginStyle from "./Otp.module.css"; // eslint-disable-line
import { verifyOtReq } from "./Action/otpAction";
import { getBen } from "./Action/getBen";
import { getAllBen } from "./Action/getAllBen";
import { ShowAlert } from "../../../GlobalComponents/ShowAlert/ShowAlert";
import Timer from "../../../GlobalComponents/Timer/Timer";
import { sendOtp } from "../Login/Action/loginAction";
import { parseUserAgent } from "../../../GlobalActions/captureDevice";
// import { getSubscriberByUserIdHandler } from "./Action/getSubscriberByUserId";
// import { getSubscriber } from "./Action/getSubscriber";

const userAgent = navigator.userAgent;

const validationSchema = Yup.object({
    otp: Yup.string()
        .matches(/^[0-9]{6}$/, "Invalid OTP")
        .required("OTP is required"),
});


interface OtpModalProps {
    showOtpModal: boolean;
    email: string;
    onClose: () => void; // This function will be called to close the modal
    setShowOtpModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const OtpModal: React.FC<OtpModalProps> = ({ showOtpModal, onClose, setShowOtpModal, email }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [input, clearInput] = useState({ otp: "" });  // eslint-disable-line
    const [resendAllowed, setResendAllowed] = useState(false); // eslint-disable-line
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: input,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            const getOtpFromSessionStorage = sessionStorage.getItem('otpCredential');
            const parseOtp = getOtpFromSessionStorage ? JSON.parse(getOtpFromSessionStorage) : null;

            try {
                const userDevice = parseUserAgent(userAgent);
                const verifyOtRes = await verifyOtReq({ id: parseOtp.id, otp: values.otp, device: userDevice, sessionType: 'WEB' }, dispatch);

                // if (verifyOtRes.userRole === "BENEFICIARY_ADMIN") {
                //     let subscriptionData = await getSubscriber(verifyOtRes.subscriberId)
               
                //     if (subscriptionData?.isSubscriptionActive !== undefined && subscriptionData?.isSubscriptionActive === false) {
                //         return navigate("/subscription")
                //     }
                // }

                await getBen(verifyOtRes.beneficiaryId, dispatch)
                await getAllBen(verifyOtRes.subscriberId, dispatch);
                ShowAlert("success", "OTP has verified");
                setIsLoading(false);
                let lastVisitedPage = sessionStorage.getItem("lastVisitedPage")

                if (lastVisitedPage) {
                    sessionStorage.removeItem("lastVisitedPage")
                    return navigate(JSON.parse(lastVisitedPage))
                }
                return navigate('/dashboard');

            } catch (error: any) {

                setIsLoading(false);
                error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
                    ShowAlert('warning', 'Please try again later')

                console.error();
            }
        },
    });

    // useEffect(() => {

    // }, [navigate]);

    const handleResendCode = async () => {
        formik.resetForm();
        setResendAllowed(false);

        try {

            const phoneNumber = sessionStorage.getItem('loginCredential') || null;
            const parsePhoneNumber = phoneNumber ? JSON.parse(phoneNumber) : null;
            if (parsePhoneNumber) {

                await sendOtp(parsePhoneNumber);
                ShowAlert("success", "OTP has been sent to your number");

            } else {
                setShowOtpModal(false)
            }
        } catch (error: any) {

            error.response?.data?.message ? ShowAlert('warning', error.response?.data?.message) :
                ShowAlert('warning', error.response?.data?.message)
            console.error();
        }
    };
    return (
        <>
            <Box>
                <Modal size={"md"} isOpen={showOtpModal} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Enter OTP</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box padding={{ md: ".25rem", base: ".5rem" }}>
                                <Flex alignItems={"flex-start"} justify={"center"}>
                                    <Stack spacing={15} mx={"auto"} width={"100%"} py={5}>
                                        <Box rounded={"lg"}>
                                            <form onSubmit={formik.handleSubmit}>
                                                <Flex alignItems={"flex-start"} w="100%">
                                                    <FormControl marginBottom={".475rem"} w="">
                                                        <OTPInput
                                                            //   id="otpInput"
                                                            inputStyle={{
                                                                backgroundColor: "rgba(255,255,255)",
                                                                border: "1px solid transparent",
                                                                borderRadius: "8px",
                                                                width: "47px",
                                                                height: "47px",
                                                                marginRight: "15px",
                                                                fontSize: "18px",
                                                                // color: "red",
                                                                fontWeight: "400",
                                                                caretColor: "blue",
                                                            }}
                                                            numInputs={6}
                                                            inputType={`number`}
                                                            value={formik.values.otp}
                                                            renderInput={(props) => <input {...props} />}
                                                            onChange={(value) =>
                                                                formik.setFieldValue('otp', `${value}`)
                                                            }
                                                        />

                                                        {formik.touched.otp && formik.errors.otp ? (
                                                            <Text color="crimson" fontSize="sm">
                                                                {formik.errors.otp}
                                                            </Text>
                                                        ) : null}
                                                    </FormControl>
                                                </Flex>

                                                <Stack spacing={5} marginTop={"10px"}>
                                                    <Stack
                                                        alignItems={"center"}
                                                        justify={"space-between"}
                                                    >
                                                        {/* <Text textAlign={"center"} color={"#3B5753"}>
                                                            Please enter the OTP sent to <b>{email} </b>
                                                            

                                                            <span
                                                                className={loginStyle.textBold}
                                                                color={"#008470"}
                                                            >
                                                            </span>
                                                        </Text> */}
                                                    </Stack>
                                                    <Stack alignItems={"center"} spacing={10}>
                                                        <Button
                                                            loadingText="Loading"
                                                            width={"50%"}
                                                            bg={"#008470"}
                                                            color={"white"}
                                                            // isDisabled={resendAllowed}
                                                            isLoading={isLoading}
                                                            _hover={{
                                                                bg: "#008470",
                                                            }}
                                                            type="submit"
                                                        >
                                                            Verify
                                                        </Button>
                                                    </Stack>
                                                    {resendAllowed ? (
                                                        <Stack pt={0} align={"center"}>
                                                            <Text alignItems={"center"}>
                                                                Not received OTP?{" "}
                                                                <Link
                                                                    onClick={() => handleResendCode()}
                                                                    color={"#008470"}
                                                                >
                                                                    Resend OTP
                                                                </Link>
                                                            </Text>
                                                        </Stack>
                                                    ) : (
                                                        <div style={{ textAlign: 'center' }}>
                                                            Resend OTP in
                                                            {(<Timer
                                                                type="otp"
                                                                time={30}
                                                                setResendAllowed={setResendAllowed}
                                                            />)} seconds
                                                        </div>
                                                    )}
                                                </Stack>
                                            </form>
                                        </Box>
                                    </Stack>
                                </Flex>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    );
};

export default OtpModal;
