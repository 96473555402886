import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import TxtComponent from "./Components/B2CRegistrationComponents/TxtComponent";
import RegistrationProcess from "./Components/B2CRegistrationComponents/RegistrationProcess";

const B2CRegistrationForm = () => {
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  return (
    <Box
      bgGradient="linear(180deg, #FFF 0%, #D2F0F2 100%)"
      position={"fixed"}
      marginTop={"85px"}
      minH={"full"}
      top={0}
      width={"full"}
    >
      <Flex
        flexDir={isMobile ? "column" : "row"}
        backgroundImage="url('https://developmentbucket.blob.core.windows.net/svgs/image_prev_ui.svg')"
        width={"80%"}
        margin={"auto"}
        justifyContent={isMobile ? "start":"space-between"}
        alignItems={"center"}
      >
        <TxtComponent />
        <RegistrationProcess />
      </Flex>
    </Box>
  );
};

export default B2CRegistrationForm;
