import { checkB2CPaymentStatus, createB2CSubscription } from "../../../../http";

export const loadRazorpayScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

export const openRazorpayCheckout = async (options: any) => {
    const res = await loadRazorpayScript();

    if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        return;
    }

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};


export const createB2CSubscriptionHandler = async (payload: { planId: string, quantity: number }) => {
    try {
        let res = await createB2CSubscription(payload)
        return res.data
    } catch (error: any) {
        console.log("subscription", error)
        alert(error?.response?.data?.message)
    }
}

export const checkB2CPaymentStatusHandler = async (payload: { payment_id: string, subscription_id: string }) => {
    try {
        let res = await checkB2CPaymentStatus(payload)
        return res.data
    } catch (error: any) {
        if (error.statusCode === 408) {
            alert('Its taking time to get payment status, try login after some time')
        }
        alert(error?.response?.data?.message)
    }
}