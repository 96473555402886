import React from "react";
import {
  Box,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  Icon,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  CloseButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
const CustomCheckIcon = (props: any) => (
  <Icon viewBox="0 0 19 15" {...props}>
    <path
      width={"50.432px"}
      d="M18.4513 3.97426L14.1353 8.29094L10.035 12.3913L8.72551 13.7008C8.16398 14.2617 7.04212 14.2617 6.48118 13.7008L0.870055 8.08963C0.507449 7.72702 0.379014 7.13019 0.485346 6.60271C0.542694 6.31298 0.67113 6.04363 0.870055 5.8447L2.1795 4.53586C2.73983 3.97432 3.8623 3.97432 4.42383 4.53586L7.60365 7.71561L11.9693 3.34941L13.9418 1.37635L14.8976 0.421148C15.4591 -0.140383 16.5816 -0.140383 17.1419 0.421148L18.4513 1.72999C18.6043 1.88346 18.716 2.07826 18.7853 2.29206C18.9711 2.86196 18.8594 3.56626 18.4513 3.97426Z"
      fill="#2A94F4"
    />
  </Icon>
);

const MemberShipCard = ({
  setMemCardActive,
  isMemCardActive,
  setB2CRegisterStatus,
}: any) => {
  let navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const onCloseEvent = () => {
    setMemCardActive(false);
  };
  const onClickRegister = () => {
    return navigate("/registration");
    // setMemCardActive(false);
    // setB2CRegisterStatus(true);
  };

  return (
    <Box>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isMemCardActive}
        onClose={onCloseEvent}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          width={isMobile ? "100%" : "100%"}
          sx={{
            bg: "transparent", // Remove background color
            boxShadow: "none", // Remove shadow
            borderRadius: "none", // Remove border radius
            p: 0, // Remove padding
            border: "none", // Remove border
            margin: 0, // Remove margin
            maxWidth: "none", // Remove max-width constraint
          }}
        >
          <Flex
            // border={"2px dotted red"}
            flexDirection={isMobile ? "column" : "row"}
            marginTop={isMobile ? "150px" : "275px"}
            justifyContent={"space-evenly"}
            height={isMobile ? "600px" : "455px"}
            p="4"
            bg="white"
          >
            <Box>
              <Box position={"relative"} top={isMobile ? "10px" : "124.5px"}>
                <Box>
                  <Image
                    width={isMobile ? "50%" : "60%"}
                    src="https://developmentbucket.blob.core.windows.net/logos/poco-care-text-logo.png"
                  />
                  <Box paddingLeft={"20px"} width={"380px"}>
                    <Text
                      color="#333333"
                      fontSize="15px"
                      fontFamily="Poppins"
                      fontWeight="500"
                      lineHeight="36px"
                      letterSpacing="1.32px"
                      wordBreak="break-word"
                    >
                      Last chance! 7 days left to get 10% off membership.
                    </Text>
                  </Box>
                </Box>
                <Box
                  position={"relative"}
                  top={isMobile ? "-295px" : "0px"}
                  left={isMobile ? "-25px" : "0px"}
                >
                  <Image
                    marginTop={isMobile ? "600px" : "0px"}
                    left={isMobile ? "0px" : "0px"}
                    width={"450px"}
                    src="https://developmentbucket.blob.core.windows.net/logos/group_b.png.png"
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={4}>
              <CloseButton
                onClick={onCloseEvent}
                position={"relative"}
                left={isMobile ? "317px" : "870px"}
                top={isMobile ? "-845px" : "0px"}
              />
              <Image
                position={"relative"}
                left={isMobile ? "300px" : "750px"}
                top={isMobile ? "-716px" : "0px"}
                right={"12px"}
                width={"20%"}
                src="https://developmentbucket.blob.core.windows.net/logos/group_a.png.png"
              />
              <Box
                position={"relative"}
                top={isMobile ? "-770px" : "-140px"}
                left={isMobile ? "25px" : "350px"}
                width={isMobile ? "303.988px" : "470px"}
                height={isMobile ? "auto" : "340px"}
                border={"1px solid rgba(0, 0.00, 0.00, 0.50)"}
                bg="white"
                boxShadow="sm"
                p={isMobile ? "8px" : "12px"}
              >
                <Text
                  color="#333"
                  fontFamily="Poppins"
                  fontSize={isMobile ? "14.229px" : "22px"}
                  fontWeight="500"
                  lineHeight="36px" // This is equivalent to 163.636%
                  letterSpacing="1.32px"
                  mb="4"
                >
                  Membership
                </Text>
                <List spacing={3}>
                  <ListItem
                    display={"flex"}
                    flexDir={"row"}
                    alignItems={"center"}
                  >
                    <ListIcon
                      as={CustomCheckIcon}
                      height={"16.136px"}
                      bg="#CCE9FF"
                      width={"16.136px"}
                      color="teal.500"
                    />
                    <Text fontSize={isMobile ? "7.761px" : "15px"}>
                      {" "}
                      Fastest response from incident to ER Reliable every time.
                    </Text>
                  </ListItem>
                  <ListItem
                    display={"flex"}
                    flexDir={"row"}
                    alignItems={"center"}
                  >
                    <ListIcon
                      as={CustomCheckIcon}
                      height={"16.136px"}
                      bg="#CCE9FF"
                      width={"16.136px"}
                      color="teal.500"
                    />
                    <Text fontSize={isMobile ? "7.761px" : "15px"}>
                      {" "}
                      Immediate assessment by Virtual Doctor.
                    </Text>
                  </ListItem>
                  <ListItem
                    display={"flex"}
                    flexDir={"row"}
                    alignItems={"center"}
                  >
                    <ListIcon
                      as={CustomCheckIcon}
                      height={"16.136px"}
                      bg="#CCE9FF"
                      width={"16.136px"}
                      color="teal.500"
                    />
                    <Text fontSize={isMobile ? "7.761px" : "15px"}>
                      {" "}
                      Patients Medical Record's, current condition and virtual
                      doctor's diagnosis sent to hospital ER pre arrival.
                    </Text>
                  </ListItem>
                  <ListItem
                    display={"flex"}
                    flexDir={"row"}
                    alignItems={"center"}
                  >
                    <ListIcon
                      as={CustomCheckIcon}
                      height={"16.136px"}
                      bg="#CCE9FF"
                      width={"16.136px"}
                      color="teal.500"
                    />
                    <Text fontSize={isMobile ? "7.761px" : "15px"}>
                      {" "}
                      Patient brought to nearest appropriately equipped hospital
                      every time. No diversion to other hospitals.
                    </Text>
                  </ListItem>
                </List>
                <Box
                  mt={4}
                  mb={2}
                  height={"1px"}
                  bgColor={"rgba(0, 0, 0, 0.10)"}
                ></Box>
                <Button
                  onClick={onClickRegister}
                  mt="3"
                  fontSize={isMobile ? "7.761px" : "15px"}
                  height={"40px"}
                  width="full"
                  bg="#01A7B5"
                  color="white"
                  _hover={{ bg: "teal.600" }}
                >
                  Register
                </Button>
              </Box>
            </Box>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MemberShipCard;
